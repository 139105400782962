import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout-solution"
import Seo from "../components/seo"

//components
import Cases from "../components/cases"
import Modal from "../components/modal/quickbase-modal"
import PartnerSwiper from "../components/swiper/partners.js"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-quickbase-system-integration"
import ExitIntentModal from "../components/modal/exit-intent-sic"
import Testimonials2 from "../components/swiper/testimonial-swiper"

class SystemIntegrationSupportPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo title={siteTitle} description={siteDescription} />
        <div id="main">
          <LazyLoadComponent>
            <ExitIntentModal />

            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container px-5">
                <div className="row">
                  <div className="col-md-12 mx-auto text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      Awesome Collaborative Partners
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "We have been working with Quandary for a year or two now.
                      They are great to work with and are very collaborative in
                      working with you to develop tools that will work best for
                      your organization. The development process from the
                      customer perspective is always simple and easy. The team
                      at Quandary is always very responsive to our needs, and
                      goes above and beyond to ensure that we are satisfied
                      customers."
                    </p>
                    <div className="col-md-12 my-5">
                      <StaticImage
                        placeholder="dominantColor"
                        className="rounded-circle img-fluid ml-auto text-center"
                        src="../images/new-images/testimonial-images/thomas.jpg"
                        alt="thomas"
                        layout="constrained"
                        height={150}
                      />
                    </div>

                    <div className="py-3">
                      <p>- Thomas Arlow, Dexko</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      INTEGRATION SUPPORT: BETTER. FASTER. EASIER.
                    </h2>
                    <p className="mt-3">
                      As your integration specialists, we use our expertise and
                      experience to help break down your bottlenecks and
                      transform your business processes.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      WORKFLOW MAPPING
                    </p>
                    <p className="my-3 pb-3">
                      We analyze your workflows FIRST, then build
                      <br />
                      the solutions you really need.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      UNMATCHED EXPERIENCE
                    </p>
                    <p className="my-3 pb-3">
                      We’ve built a range of applications and automations <br />
                      from scratch for businesses large and small.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      CONTINUOUS PROCESS IMPROVEMENT
                    </p>
                    <p className="my-3 pb-3">
                      We create a custom roadmap designed to streamline
                      <br /> automation and provide the highest ROI.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>

            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container px-5">
                <div className="row">
                  <div className="col-md-12 mx-auto text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">Quick Start Program</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "Alex and the Quandary team were outstanding. They
                      provided exceptional insight into solving various design
                      issues, coached us on some of the "tips and tricks" when
                      working in the environment, and accommodated our shifting
                      calendar and development priorities. They are sharp,
                      knowledgeable, and easy to work with. HIGHLY recommended
                      for anyone entering the Quickbase world."
                    </p>
                    <div className="col-md-12 my-5">
                      <StaticImage
                        placeholder="dominantColor"
                        className="rounded-circle img-fluid ml-auto text-center"
                        src="../images/new-images/testimonial-images/jim.jpg"
                        alt="jim"
                        layout="constrained"
                        height={150}
                      />
                    </div>
                    <div className="py-3">
                      <p>- Jim Carpenter, Flexible Business Systems</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                </div>

                <div className="text-center">
                  <Link
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                    to="/case-studies/"
                  >
                    More Case Studies
                  </Link>
                </div>
              </div>
            </section>
            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>“NO FUSS” INTEGRATION SUPPORT </h1>
                    <p>
                      Outsource system integration stress and frustration to
                      integration specialists who will help you compete with
                      enterprise organizations. Without the enterprise price
                      tag.
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container px-5">
                <div className="row">
                  <div className="col-md-12 mx-auto text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      Customized program made simple...
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "Customized program made simple... This team does a great
                      job of communicating changes, scheduling calls to chat,
                      and doing what they can to create the program the business
                      needs and wants. They are fun to chat with, real people
                      who take on new challenges and make them a reality."
                    </p>
                    <div className="col-md-12 my-5">
                      <StaticImage
                        placeholder="dominantColor"
                        className="rounded-circle img-fluid ml-auto text-center"
                        src="../images/new-images/testimonial-images/ryan.jpg"
                        alt="ryan"
                        layout="constrained"
                        height={150}
                      />
                    </div>
                    <div className="py-3">
                      <p>
                        - Carmen Whiting, TOTAL PACKAGE PROFESSIONAL SERVICES
                        LLC
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium">
                        FINALLY.{" "}
                        <span className="text-green">
                          A BETTER WAY TO BRIDGE GAPS BETWEEN SYSTEMS.
                        </span>
                      </h2>
                      <p>
                        Don’t waste your time struggling to connect your
                        application stack. Delegate the heavy
                        <br /> lifting to your personal integrations partner.
                      </p>
                      <div className="col-lg-5 mx-auto text-center pt-3 ">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Testimonials2 />
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>
                <LazyLoadComponent>
                  <PartnerSwiper />
                </LazyLoadComponent>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default SystemIntegrationSupportPage

export const SystemIntegrationSupportPageQuery = graphql`
  query SystemIntegrationSupportPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
